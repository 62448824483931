// import React from "react";
// import "./Explore.css";
// import exploreImage from "../Images/explore.svg"; // Ensure the path to the image is correct

// const Explore = () => {
//   return (
//     <section className="explore">
//       <img
//         src={exploreImage}
//         alt="People exploring codeXodus"
//         className="explore__image"
//       />
//       <div className="explore__overlay">
//         <h1 className="explore__title">
//           “Explore CodeXodus today and take<br/> the first step towards<br/> mastering the
//           world of coding”
//         </h1>
//       </div>
//     </section>
//   );
// };

// export default Explore;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./Explore.css";
import exploreImage from "../Images/explore.svg"; // Ensure the path to the image is correct

const Explore = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
      once: true, // Only animate once on scroll
    });
  }, []);

  return (
    <section className="explore">
      <img
        src={exploreImage}
        alt="People exploring codeXodus"
        className="explore__image"
        data-aos="fade-right"
      />
      <div className="explore__overlay">
        <h1 className="explore__title">
          {/* “Explore CodeXodus today and take
          <br /> the first step towards
          <br /> mastering the world of coding” */}
          “Join CodeXodus today—where beginners become experts and coding skills
          open doors to endless career opportunities!”
        </h1>
      </div>
    </section>
  );
};

export default Explore;
