// import React from "react";
// import "./Certificate.css";
// import certificateImage from "../Images/cert.svg"; // Adjust this path as needed

// const Certificate = () => {
//   return (
//     <section className="certificate">
//       <div className="certificate__content">
//         <p className="certificate__pre-title">
//           Prove your skills and get noticed
//         </p>
//         <h1 className="certificate__title">
//           Earn Your Coding <br />
//           Certificate
//         </h1>
//         <ul className="certificate__benefits">
//           <li className="certificate__benefit-item">
//             <span className="certificate__icon icon-skill"></span>
//             <div className="certificate__benefit-text">
//               <h2 className="certificate__benefit-title">Show Your Skills</h2>
//               <p className="certificate__benefit-description">
//                 Highlight your coding knowledge to employers.
//               </p>
//             </div>
//           </li>
//           <li className="certificate__benefit-item">
//             <span className="certificate__icon icon-rocket"></span>
//             <div className="certificate__benefit-text">
//               <h2 className="certificate__benefit-title">
//                 Boost Your Job Prospects
//               </h2>
//               <p className="certificate__benefit-description">
//                 Stand out when applying for jobs.
//               </p>
//             </div>
//           </li>
//           <li className="certificate__benefit-item">
//             <span className="certificate__icon icon-certificate"></span>
//             <div className="certificate__benefit-text">
//               <h2 className="certificate__benefit-title">Be Job-Ready</h2>
//               <p className="certificate__benefit-description">
//                 Our certificate shows you're prepared for real-world challenges.
//               </p>
//             </div>
//           </li>
//         </ul>
//       </div>
//       <div className="certificate__image-wrapper">
//         <img
//           src={certificateImage}
//           alt="Certificate illustration"
//           className="certificate__image"
//         />
//       </div>
//     </section>
//   );
// };

// export default Certificate;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./Certificate.css";
import certificateImage from "../Images/cert.svg"; // Adjust this path as needed

const Certificate = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
      once: true, // Only animate once on scroll
    });
  }, []);

  return (
    <section className="certificate">
      <div className="certificate__content" data-aos="fade-right">
        <p className="certificate__pre-title">
          Prove your skills and get noticed
        </p>
        <h1 className="certificate__title">
          Earn Your Coding <br />
          Certificate
        </h1>
        <ul className="certificate__benefits">
          <li className="certificate__benefit-item">
            <span className="certificate__icon icon-skill"></span>
            <div className="certificate__benefit-text">
              <h2 className="certificate__benefit-title">Show Your Skills</h2>
              <p className="certificate__benefit-description">
                Highlight your coding knowledge to employers.
              </p>
            </div>
          </li>
          <li className="certificate__benefit-item">
            <span className="certificate__icon icon-rocket"></span>
            <div className="certificate__benefit-text">
              <h2 className="certificate__benefit-title">
                Boost Your Job Prospects
              </h2>
              <p className="certificate__benefit-description">
                Stand out when applying for jobs.
              </p>
            </div>
          </li>
          <li className="certificate__benefit-item">
            <span className="certificate__icon icon-certificate"></span>
            <div className="certificate__benefit-text">
              <h2 className="certificate__benefit-title">Be Job-Ready</h2>
              <p className="certificate__benefit-description">
                Our certificate shows you're prepared for real-world challenges.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div className="certificate__image-wrapper" data-aos="fade-left">
        <img
          src={certificateImage}
          alt="Certificate illustration"
          className="certificate__image"
        />
      </div>
    </section>
  );
};

export default Certificate;
