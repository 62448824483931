import Benefits from "./Benefits";
import PlacementTraining from "./PlacementTraining";
import BenefitsSection from "./BenefitsSection";
import FeaturesSection from "./FeaturesSection";
import NAACTable from "./NAACTable";
import WhoCanBenefit from "./WhoCanBenefit";
import StatisticsCard from "./StatisticsCard";
import CodeXodus from "./CodeXodus";
import AboutProduct from "./AboutProduct";
import AvailableCourses from "./AvailableCourses";
import Empower from "./Empower";
import Certificate from "./Certificate";
import Explore from "./Explore";
import CallToAction from "./CallToAction";
import ContactUs from "./ContactUs";

function App() {
  return (
    <div className="App">
      <CodeXodus />
      <StatisticsCard />
      <AboutProduct />
      <FeaturesSection />
      <AvailableCourses />
      <WhoCanBenefit />

      <Empower />
      <Certificate />
      <Explore />
      <BenefitsSection />
      <CallToAction />
    </div>
  );
}

export default App;
