// import React, { useEffect } from "react";
// import AOS from "aos";
// import "aos/dist/aos.css"; // Import AOS styles
// import "./WhoCanBenefit.css";

// const WhoCanBenefit = () => {
//   // Initialize AOS
//   useEffect(() => {
//     AOS.init({
//       duration: 1200, // Animation duration in milliseconds
//       once: true, // Only animate once on scroll
//     });
//   }, []);

//   return (
//     <section className="who-can-benefit">
//       <h1 className="who-can-benefit__title" data-aos="fade-up">
//         Who Can Benefit
//       </h1>
//       <div className="who-can-benefit__container">
//         <article className="who-can-benefit__block" data-aos="fade-right">
//           <div className="who-can-benefit__image who-can-benefit__image--university"></div>
//           <p className="who-can-benefit__label">Educational Institutions</p>
//           <p className="who-can-benefit__description">
//             Our platform aligns with NEP 2020, offering a comprehensive and
//             inclusive approach to enhance coding education within educational
//             institutions. Empower your curriculum with tools that foster
//             interactive learning, assessment, and inclusivity.
//           </p>
//         </article>
//         <article
//           className="who-can-benefit__block"
//           data-aos="fade-up"
//           data-aos-delay="200"
//         >
//           <div className="who-can-benefit__image who-can-benefit__image--students"></div>
//           <p className="who-can-benefit__label">Students & Aspiring Coders</p>
//           <p className="who-can-benefit__description">
//             Empower your coding journey with a tailored learning experience,
//             whether you're a student building foundational skills or a job
//             seeker preparing for technical interviews.
//           </p>
//         </article>
//         <article
//           className="who-can-benefit__block"
//           data-aos="fade-left"
//           data-aos-delay="400"
//         >
//           <div className="who-can-benefit__image who-can-benefit__image--institutes"></div>
//           <p className="who-can-benefit__label">Educators & Trainers</p>
//           <p className="who-can-benefit__description">
//             Equip students with the skills and confidence needed to excel in the
//             job market. Our platform offers tailored training, mock interviews,
//             and competitive coding environments to prepare students for
//             real-world technical challenges.
//           </p>
//         </article>
//       </div>
//     </section>
//   );
// };

// export default WhoCanBenefit;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./WhoCanBenefit.css";

const WhoCanBenefit = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
      once: true, // Only animate once on scroll
    });
  }, []);

  return (
    <section className="who-can-benefit">
      <h1 className="who-can-benefit__title" data-aos="fade-up">
        Who Can Benefit
      </h1>
      <div className="who-can-benefit__container">
        <article className="who-can-benefit__block" data-aos="fade-right">
          <div className="who-can-benefit__image who-can-benefit__image--university"></div>
          <p className="who-can-benefit__label">Educational Institutions</p>
          <ul className="who-can-benefit__description">
            <li>
              <strong>Schools & Colleges:</strong> Equip students with tools
              that meet NAAC, NBA, and NEP 2020 standards for innovative
              education.
            </li>
            <li>
              <strong>Coaching Centers:</strong> Coaching Centers: Provide
              customizable coding practice for competitive exam preparation.
            </li>
            <li>
              <strong>Training Programs:</strong> Use CodeXodus modules for
              impactful, real-world skill development.
            </li>
          </ul>
        </article>
        <article
          className="who-can-benefit__block"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="who-can-benefit__image who-can-benefit__image--students"></div>
          <p className="who-can-benefit__label">Students & Aspiring Coders</p>
          <ul className="who-can-benefit__description">
            <li>
              <strong>Beginners:</strong> CodeXodus offers zero-to-pro paths,
              making coding accessible for newcomers.
            </li>
            <li>
              <strong>Advanced Learners:</strong> Enhance your skills with
              challenging problems and algorithm exercises for interviews.
            </li>
            <li>
              <strong>Career-Focused Coders:</strong> Access real-world
              scenarios and problem sets to develop job-ready skills.
            </li>
          </ul>
        </article>
        <article
          className="who-can-benefit__block"
          data-aos="fade-left"
          data-aos-delay="400"
        >
          <div className="who-can-benefit__image who-can-benefit__image--institutes"></div>
          <p className="who-can-benefit__label">Educators & Trainers</p>
          <ul className="who-can-benefit__description">
            <li>
              <strong>Faculty Members:</strong> Use robust assessment tools and
              proctored exams to effectively enhance instruction and streamline
              grading.
            </li>
            <li>
              <strong>Training Facilitators:</strong> Leverage CodeXodus’s
              innovative platform for customized tests and curriculum
              adaptation, enabling effective tracking of learner progress and
              outcomes.
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default WhoCanBenefit;
