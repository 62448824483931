import React, { useRef, useEffect } from "react";
import emailjs from "emailjs-com";
import "./ContactUs.css";
import Image from "../Images/con.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const ContactUs = () => {
  const form = useRef();

  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000 }); // You can adjust the duration
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_c0jccxt", // Replace with your EmailJS Service ID
        "template_0oyo6l8", // Replace with your EmailJS Template ID
        form.current,
        "EK4yzneis1PSb1pF6" // Replace with your EmailJS User ID
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send the message. Please try again.");
        }
      );
    e.target.reset(); // Reset form after submission
  };

  return (
    <>
      <img
        src={Image}
        alt="Contact Us Background"
        className="contact-background__image"
        data-aos="fade-in" // Apply AOS animation
      />
      <h1 className="contact-background__title">Contact Us</h1>
      <div className="contact-us" data-aos="fade-up">
        <form className="contact-us__form" ref={form} onSubmit={sendEmail}>
          <div className="contact-us__row" data-aos="fade-up">
            <div className="contact-us__field">
              <label className="contact-us__label">Name</label>
              <input
                type="text"
                name="user_name"
                className="contact-us__input"
                placeholder="Your full name"
                required
              />
            </div>
            <div className="contact-us__field">
              <label className="contact-us__label">Email</label>
              <input
                type="email"
                name="user_email"
                className="contact-us__input"
                placeholder="Your email"
                required
              />
            </div>
          </div>

          <div className="contact-us__row" data-aos="fade-up">
            <div className="contact-us__field">
              <label className="contact-us__label">Phone Number</label>
              <input
                type="text"
                name="user_phone"
                className="contact-us__input"
                placeholder="+91 0000000000"
                required
              />
            </div>
            <div className="contact-us__field">
              <label className="contact-us__label">Interested Course</label>
              <input
                type="text"
                name="user_course"
                className="contact-us__input"
                placeholder="Course"
                required
              />
            </div>
          </div>

          <div className="contact-us__field" data-aos="fade-up">
            <label className="contact-us__label">Tell me about Yourself</label>
            <textarea
              name="message"
              className="contact-us__textarea"
              placeholder="Write your message"
              required
            ></textarea>
          </div>

          <button
            type="submit"
            className="contact-us__button"
            data-aos="zoom-in"
          >
            Send
          </button>
        </form>
      </div>
    </>
  );
};

export default ContactUs;


