// import React from "react";
// import "./BenefitsSection.css";
// import Image from "../Images/Group22.svg";

// const BenefitsSection = () => {
//   return (
//     <section className="benefits-section">
//       <div className="benefits-section__content">
//         <div className="benefits-section__image-container">
//           <img
//             src={Image}
//             alt="Education Benefits"
//             className="benefits-section__image"
//           />
//         </div>
//         <div className="benefits-section__text-content">
//           <h3 className="benefits-section__brand">code<span className="highlight">X</span>odus</h3>
//           <h1 className="benefits-section__heading">
//             Comprehensive Learning Environment
//           </h1>
//           <ul className="benefits-section__list">
//             <li className="benefits-section__list-item">
//               <span className="benefits-section__list-icon"></span> 100+ Challenges: Wide range of problems in various languages.
//             </li>
//             <li className="benefits-section__list-item">
//               <span className="benefits-section__list-icon"></span> Core Languages & Topics: Covers Java, Python, DS, DAA & more.
//             </li>
//             <li className="benefits-section__list-item">
//               <span className="benefits-section__list-icon"></span> Code Editor: Syntax highlighting, debugging, & auto-completion.
//             </li>
//             <li className="benefits-section__list-item">
//               <span className="benefits-section__list-icon"></span> Real-World Scenarios: Problems like placement interview questions.
//             </li>
//             <li className="benefits-section__list-item">
//               <span className="benefits-section__list-icon"></span> Auto-Evaluation: Integrated compiler with instant feedback, 24/7 access.
//             </li>
//             <li className="benefits-section__list-item">
//               <span className="benefits-section__list-icon"></span> Industry based logical, mandatory, & complexity test cases.
//             </li>
//           </ul>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default BenefitsSection;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./BenefitsSection.css";
import Image from "../Images/Certification.svg";

const BenefitsSection = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
      once: true, // Only animate once on scroll
    });
  }, []);

  return (
    <section className="benefits-section">
      <div className="benefits-section__content">
        <div
          className="benefits-section__image-container"
          data-aos="fade-right"
        >
          <img
            src={Image}
            alt="Education Benefits"
            className="benefits-section__image"
          />
        </div>
        {/* <div className="benefits-section__text-content" data-aos="fade-left">
          <h3 className="benefits-section__brand">
            code<span className="highlight">X</span>odus
          </h3>
          <h1 className="benefits-section__heading">
            Learn, Practice and Master Coding With Us
          </h1>
          <ul className="benefits-section__list">
            <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <strong>Extensive Coding:</strong> Master coding with 100+
              challenges.
            </li>
            <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <strong> Personalized Growth:</strong> From beginner to advanced
              level on your own.
            </li>
            <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <strong>Instant Feedback:</strong> Improve faster with real-time,
              auto evaluations.
            </li>
            <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <strong>AI Assistance & 24/7 Support:</strong> Get personalized
              guidance and support.
            </li>
            <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <strong>Certification & Badge:</strong>
              Showcase with industry-recognized credentials.
            </li>
            <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <strong>Learn in Your Language:</strong> Overcome barriers with
              regional language support.
            </li>
          </ul>
        </div> */}
        <div className="benefits-section__text-content" data-aos="fade-left">
          <h3 className="benefits-section__brand">
            code<span className="highlight">X</span>odus
          </h3>
          <h1 className="benefits-section__heading">
            Learn, Practice and Master Coding With Us
          </h1>
          <ul className="benefits-section__list">
            {/* <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <strong>Extensive Coding: </strong> Master coding with 100+
              challenges.
            </li>
            <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <strong>Personalized Growth: </strong> From beginner to advanced
              level on your own.
            </li>
            <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <strong>Instant Feedback: </strong> Improve faster with real-time,
              auto evaluations.
            </li> */}
            <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <span>
                <strong>Extensive Coding:</strong> Master coding with 100+
                challenges.
              </span>
            </li>

            <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <span>
                <strong>Personalized Growth:</strong> From beginner to advanced
                level on your own.
              </span>
            </li>

            <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <span>
                <strong>Instant Feedback:</strong> Improve faster with
                real-time, auto evaluations.
              </span>
            </li>

            <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <span>
                <strong>AI Assistance & 24/7 Support: </strong> Get personalized
                guidance and support.
              </span>
            </li>
            <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <span>
                <strong>Certification & Badge: </strong> Showcase with
                industry-recognized credentials.
              </span>
            </li>
            <li className="benefits-section__list-item">
              <span className="benefits-section__list-icon"></span>
              <span>
                <strong>Learn in Your Language: </strong> Overcome barriers with
                regional language support.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
