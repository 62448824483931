// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAVq3Z0zQ4VLks_RqXulu1mo6Wo2vfPVrA",
    authDomain: "codexodus-56c15.firebaseapp.com",
    projectId: "codexodus-56c15",
    storageBucket: "codexodus-56c15.firebasestorage.app",
    messagingSenderId: "208262392767",
    appId: "1:208262392767:web:b0ff96a06b383d5206cf0a",
    measurementId: "G-3RP6XVT8WJ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
