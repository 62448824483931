// import React, { useEffect } from "react";
// import AOS from "aos";
// import "aos/dist/aos.css"; // Import AOS styles
// import "./FeaturesSection.css";
// import Image1 from "../Images/F1.svg";
// import Image2 from "../Images/F2.svg";

// import Image3 from "../Images/F3.svg";

// import Image4 from "../Images/F4.svg";

// import Image5 from "../Images/F5.svg";

// import Image6 from "../Images/F6.svg";

// import Image7 from "../Images/F7.svg";

// import Image8 from "../Images/F8.svg";

// import Image9 from "../Images/F9.svg";

// const Features = () => {

//   const features = [
//     {
//       id: 1,
//       title: "Comprehensive Coding Environment",
//       description: [
//         "100+ Coding Problems: Tackle a wide variety of challenges to build real skills.",
//         "Interview-Style Scenarios: Practice coding problems similar to real tech interviews.",
//         "Job Placement Prep: Work on problems that mirror company technical tests.",
//         "Skill-Building Variety: Access diverse challenges across coding topics.",
//       ],
//       icon: Image1,
//       bgColor: "#FFA726", // Orange
//     },
//     {
//       id: 2,
//       title: "AI Assistance and Technical Support",
//       description: [
//         "Real-Time Guidance: Get instant tips from our AI-powered assistant.",
//         "Expert Help on Demand: Access support whenever you're stuck.",
//         "Problem Solving Made Easy: Receive direct technical assistance.",
//         "24/7 Support: Personalized help anytime for smooth learning.",
//       ],
//       icon: Image2,
//       bgColor: "#42A5F5", // Blue
//     },
//     {
//       id: 3,
//       title: "Regional Language Support",
//       description: [
//         "Understand What to Solve: Focus on coding, not translation, with descriptions in your language.",
//         "Multi-Language Support: Learn seamlessly in Tamil, Hindi, Arabic, French, and more.",
//         "Empowering All Learners: Gain confidence coding in a language you know best.",
//         "Inclusive Coding Community: Breaking language barriers for a diverse future in tech.",
//       ],
//       icon: Image3,
//       bgColor: "#FFB300", // Yellow
//     },
//     {
//       id: 4,
//       title: "Personalized Learning Levels",
//       description: [
//         "Beginner-Friendly: Master coding even with zero prior experience.",
//         "Self-Paced Growth: Progress at your own speed, from basic to advanced.",
//         "Confidence Building: Develop skills step-by-step through structured levels.",
//         "Tailored Support: Get guidance that aligns with your unique learning goals.",
//       ],
//       icon: Image4,
//       bgColor: "#29B6F6", // Light Blue
//     },
//     {
//       id: 5,
//       title: "Auto-Evaluation and Instant Feedback",
//       description: [
//         "Immediate Feedback: Get detailed insights on each solution instantly.",
//         "Comprehensive Testing: Validate code with logical, mandatory, and complexity cases.",
//         "Faster Learning: Improve quickly with targeted feedback on performance.",
//         "Time-Saving Evaluation: Automated assessments speed up your progress.",
//       ],
//       icon: Image5,
//       bgColor: "#AB47BC", // Purple
//     },
//     {
//       id: 6,
//       title: "Certification and Badges",
//       description: [
//         "Level-Up Certificates: Showcase your achievements at every stage.",
//         "Resume Boost: Add recognized certifications to impress employers.",
//         "Milestone Badges: Celebrate progress with achievement badges.",
//         "Stand Out: Demonstrate commitment and skills to potential employers.",
//       ],
//       icon: Image6,
//       bgColor: "#66BB6A", // Green
//     },
//     {
//       id: 7,
//       title: "Leaderboard and Competitive Environment",
//       description: [
//         "Rank and Compete: Track your position and boost motivation.",
//         "Friendly Competition: Join a vibrant community of coders.",
//         "Push Your Limits: Challenge yourself to climb the ranks.",
//         "Peer Motivation: Stay engaged by coding alongside peers.",
//       ],
//       icon: Image7,
//       bgColor: "#8D6E63", // Brown
//     },
//     {
//       id: 8,
//       title: "Customizable Testing and Contests",
//       description: [
//         "Tailored Testing: Choose topics and difficulty levels to focus your practice.",
//         "Real-World Preparation: Timed contests mirror real coding assessments.",
//         "Competitive Environment: Push your limits with timer-controlled challenges.",
//         "Career Readiness: Practice under test-like conditions to boost confidence.",
//       ],
//       icon: Image8,
//       bgColor: "#EC407A", // Pink
//     },
//     {
//       id: 9,
//       title: "Detailed Analytics and Progress Tracking",
//       description: [
//         "Insightful Feedback: Identify strengths and areas to improve.",
//         "Topic-Specific Tracking: Monitor progress in key coding skills.",
//         "Personalized Learning Path: Adjust based on detailed analytics.",
//         "Measurable Growth: Stay motivated with visible progress over time.",
//       ],
//       icon: Image9,
//       bgColor: "#78909C", // Blue-Grey
//     },
//   ];

//   // Initialize AOS when the component mounts
//   useEffect(() => {
//     AOS.init({
//       duration: 1200, // Animation duration in milliseconds
//       once: true, // Only animate once on scroll
//     });
//   }, []);

//   return (
//     <section className="features">
//       <h1 className="features__title" data-aos="fade-up">
//         Features of CodeXodus
//       </h1>
//       <div className="features__grid">
//         {features.map((feature, index) => (
//           <div
//             key={feature.id}
//             className="features__card"
//             data-aos="zoom-in"
//             data-aos-delay={`${index * 100}`} // Cascading effect
//           >
//             <div
//               className="features__icon-container"
//               style={{ backgroundColor: feature.bgColor }}
//             >
//               <img
//                 src={feature.icon}
//                 alt={feature.title}
//                 className="features__icon"
//               />
//             </div>
//             <h2 className="features__card-title">{feature.title}</h2>
//             <ul className="features__description">
//               {feature.description.map((item, index) => (
//                 <li key={index}>{item}</li>
//               ))}
//             </ul>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// };

// export default Features;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./FeaturesSection.css";
import Image1 from "../Images/F1.svg";
import Image2 from "../Images/F2.svg";
import Image3 from "../Images/F3.svg";
import Image4 from "../Images/F4.svg";
import Image5 from "../Images/F5.svg";
import Image6 from "../Images/F6.svg";
import Image7 from "../Images/F7.svg";
import Image8 from "../Images/F8.svg";
import Image9 from "../Images/F9.svg";

const Features = () => {
  const features = [
    {
      id: 1,
      title: "Comprehensive Coding Environment",
      description: [
        "100+ Coding Problems: Tackle a wide variety of challenges to build real skills.",
        "Interview-Style Scenarios: Practice coding problems similar to real tech interviews.",
        "Job Placement Prep: Work on problems that mirror company technical tests.",
        "Skill-Building Variety: Access diverse challenges across coding topics.",
      ],
      icon: Image1,
      bgColor: "#FFA726", // Orange
    },
    {
      id: 2,
      title: "AI Assistance and Technical Support",
      description: [
        "Real-Time Guidance: Get instant tips from our AI-powered assistant.",
        "Expert Help on Demand: Access support whenever you're stuck.",
        "Problem Solving Made Easy: Receive direct technical assistance.",
        "24/7 Support: Personalized help anytime for smooth learning.",
      ],
      icon: Image2,
      bgColor: "#42A5F5", // Blue
    },
    {
      id: 3,
      title: "Regional Language Support",
      description: [
        "Understand What to Solve: Focus on coding, not translation, with descriptions in your language.",
        "Multi-Language Support: Learn seamlessly in Tamil, Hindi, Arabic, French, and more.",
        "Empowering All Learners: Gain confidence coding in a language you know best.",
        "Inclusive Coding Community: Breaking language barriers for a diverse future in tech.",
      ],
      icon: Image3,
      bgColor: "#FFB300", // Yellow
    },
    {
      id: 4,
      title: "Personalized Learning Levels",
      description: [
        "Beginner-Friendly: Master coding even with zero prior experience.",
        "Self-Paced Growth: Progress at your own speed, from basic to advanced.",
        "Confidence Building: Develop skills step-by-step through structured levels.",
        "Tailored Support: Get guidance that aligns with your unique learning goals.",
      ],
      icon: Image4,
      bgColor: "#29B6F6", // Light Blue
    },
    {
      id: 5,
      title: "Auto-Evaluation and Instant Feedback",
      description: [
        "Immediate Feedback: Get detailed insights on each solution instantly.",
        "Comprehensive Testing: Validate code with logical, mandatory, and complexity cases.",
        "Faster Learning: Improve quickly with targeted feedback on performance.",
        "Time-Saving Evaluation: Automated assessments speed up your progress.",
      ],
      icon: Image5,
      bgColor: "#AB47BC", // Purple
    },
    {
      id: 6,
      title: "Certification and Badges",
      description: [
        "Level-Up Certificates: Showcase your achievements at every stage.",
        "Resume Boost: Add recognized certifications to impress employers.",
        "Milestone Badges: Celebrate progress with achievement badges.",
        "Stand Out: Demonstrate commitment and skills to potential employers.",
      ],
      icon: Image6,
      bgColor: "#66BB6A", // Green
    },
    {
      id: 7,
      title: "Leaderboard and Competitive Environment",
      description: [
        "Rank and Compete: Track your position and boost motivation.",
        "Friendly Competition: Join a vibrant community of coders.",
        "Push Your Limits: Challenge yourself to climb the ranks.",
        "Peer Motivation: Stay engaged by coding alongside peers.",
      ],
      icon: Image7,
      bgColor: "#8D6E63", // Brown
    },
    {
      id: 8,
      title: "Customizable Testing and Contests",
      description: [
        "Tailored Testing: Choose topics and difficulty levels to focus your practice.",
        "Real-World Preparation: Timed contests mirror real coding assessments.",
        "Competitive Environment: Push your limits with timer-controlled challenges.",
        "Career Readiness: Practice under test-like conditions to boost confidence.",
      ],
      icon: Image8,
      bgColor: "#EC407A", // Pink
    },
    {
      id: 9,
      title: "Detailed Analytics and Progress Tracking",
      description: [
        "Insightful Feedback: Identify strengths and areas to improve.",
        "Topic-Specific Tracking: Monitor progress in key coding skills.",
        "Personalized Learning Path: Adjust based on detailed analytics.",
        "Measurable Growth: Stay motivated with visible progress over time.",
      ],
      icon: Image9,
      bgColor: "#78909C", // Blue-Grey
    },
  ];

  // Initialize AOS when the component mounts
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
      once: true, // Only animate once on scroll
    });
  }, []);

  return (
    <section className="features">
      <h1 className="features__title" data-aos="fade-up">
        Features of CodeXodus
      </h1>
      <div className="features__grid">
        {features.map((feature, index) => (
          <div
            key={feature.id}
            className="features__card"
            data-aos="zoom-in"
            data-aos-delay={`${index * 100}`} // Cascading effect
          >
            <div
              className="features__icon-container"
              style={{ backgroundColor: feature.bgColor }}
            >
              <img
                src={feature.icon}
                alt={feature.title}
                className="features__icon"
              />
            </div>
            <h2 className="features__card-title">{feature.title}</h2>
            <ul className="features__description">
              {feature.description.map((item, index) => {
                const [boldPart, regularPart] = item.split(":");
                return (
                  <li key={index}>
                    <strong>{boldPart}:</strong> {regularPart}
                  </li>
                );
              })}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
