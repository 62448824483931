import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./AboutProduct.css";
import AboutImage from "../Images/about.svg";

const AboutProduct = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });

    // Check device type and set isMobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="about-product">
      <div className="about-product__image-container" data-aos="fade-right">
        <img
          className="about-product__image"
          src={AboutImage}
          alt="CodeXodus Product"
        />
      </div>
      <div className="about-product__content" data-aos="fade-left">
        <h2 className="about-product__title">About Our Product</h2>
        <ul className="about-product__list">
          {isMobile ? (
            <>
              <li className="about-product__list-item">
                <span className="about-product__bullet"></span>
                CodeXodus is an innovative coding practice and learning platform
                designed to enhance programming skills in students.
              </li>
              <li className="about-product__list-item">
                <span className="about-product__bullet"></span>
                It offers a comprehensive approach to coding education, covering
                everything from foundational programming concepts to advanced
                problem-solving techniques.
              </li>
            </>
          ) : (
            <>
              <li className="about-product__list-item">
                <span className="about-product__bullet"></span>
                {/* CodeXodus is an innovative coding practice and
                <br />
                learning platform designed to enhance
                <br />
                programming skills in students. */}
                CodeXodus is an AI-powered platform that helps students and
                professionals master programming. With multilingual support and
                coding challenges, it builds real-world skills from basics to
                advanced algorithms.
              </li>
              <li className="about-product__list-item">
                <span className="about-product__bullet"></span>
                {/* It offers a comprehensive approach to coding
                <br /> education, covering everything from foundational <br />
                programming concepts to advanced problem-
                <br />
                solving techniques. */}
                CodeXodus bridges academia and industry by offering skills
                aligned with modern technical demands. It supports institutions
                with an ICT-enabled, accredited curriculum to enhance student
                success and institutional excellence.
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AboutProduct;
