import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Empower.css";
import Image1 from "../Images/lan.svg";
import Image2 from "../Images/1.svg";
import Image3 from "../Images/2.svg";

const Empower = () => {
  const cards = [
    {
      id: 1,
      title: "AI-Based Learning for Personal Growth",
      description:
        "Our AI-powered assistant offers instant, personalized feedback, helping you accelerate your coding skills with every session.",
      image: Image2,
      imagePosition: "top", // image at the top
    },
    {
      id: 2,
      title: "Regional Language Support for Inclusivity",
      description:
        "Learn comfortably in your preferred language—CodeXodus breaks language barriers so you can focus on mastering coding.",
      image: Image1,
      imagePosition: "bottom", // image at the bottom
    },
    {
      id: 3,
      title: "Placement-Ready Skills and Certifications",
      description:
        "Build skills that employers value and earn certifications that set you apart, ensuring you’re fully prepared for career success.",
      image: Image3,
      imagePosition: "top", // image at the top
    },
  ];

  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
      once: true, // Only animate once on scroll
    });
  }, []);

  return (
    <div>
      <h1 className="empower__title" data-aos="fade-up">
        Empower Your Coding Journey
      </h1>
      <section className="empower">
        <div className="empower__grid">
          {cards.map((card, index) => (
            <div
              key={card.id}
              className="empower__card"
              data-aos="fade-up"
              data-aos-delay={`${index * 100}`} // Staggered animation delay
            >
              {card.imagePosition === "top" && (
                <img
                  src={card.image}
                  alt={card.title}
                  className="empower__image"
                />
              )}
              <h2 className="empower__card-title">{card.title}</h2>
              <p className="empower__description">{card.description}</p>
              {card.imagePosition === "bottom" && (
                <img
                  src={card.image}
                  alt={card.title}
                  className="empower__image"
                />
              )}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Empower;
